import * as R from 'ramda';
import { createSelector } from 'reselect';

import { genDataSource, isAnyCustomerMapped, getMappedNumber } from './customerMapping.utils';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector(['psa', 'customerMapping']);

export const mappingSelector = createSelector(
  moduleSelector,
  R.prop('mapping')
);

const clientsSelector = createSelector(
  moduleSelector,
  R.prop('clients')
);

const clientsDataSelector = createSelector(
  clientsSelector,
  R.prop('data')
);

export const isCustomerMappedSelector = createSelector(
  clientsDataSelector,
  isAnyCustomerMapped
);

export const isClientsLoadedSelector = createSelector(
  clientsSelector,
  R.prop('isLoaded')
);

const isClientsLoadingSelector = createSelector(
  clientsSelector,
  R.prop('isLoading')
);

export const isClientsEmptySelector = createSelector(
  isClientsLoadedSelector,
  clientsDataSelector,
  (isLoaded, clients) => isLoaded && R.isEmpty(clients)
);

const psaClientsSelector = createSelector(
  moduleSelector,
  R.prop('psaClients')
);

export const psaClientsDataSelector = createSelector(
  psaClientsSelector,
  R.prop('data')
);

const isPsaClientsLoadedSelector = createSelector(
  psaClientsSelector,
  R.prop('isLoaded')
);

const isPsaClientsLoadingSelector = createSelector(
  psaClientsSelector,
  R.prop('isLoading')
);

export const isAllLoadedSelector = createSelector(
  isClientsLoadedSelector,
  isPsaClientsLoadedSelector,
  R.and
);

export const isAnyLoadingSelector = createSelector(
  isClientsLoadingSelector,
  isPsaClientsLoadingSelector,
  R.or
);

export const dataSourceSelector = createSelector(
  mappingSelector,
  clientsDataSelector,
  psaClientsDataSelector,
  genDataSource
);

export const mappedNumberSelector = createSelector(
  clientsDataSelector,
  getMappedNumber
);

export const totalNumberSelector = createSelector(
  clientsDataSelector,
  psaClientsDataSelector,
  (clients, psaClients) => R.min(clients.length, psaClients.length)
);
