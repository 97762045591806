import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('mspData');

export const orgIdSelector = createSelector(
  moduleSelector,
  R.pathOr('', ['organizations', 0, 'orgId'])
);
