import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('psa.connections');
const genOpts = langHelper.genLangOptions(genId);

export default {
  psaConnectionFailure: genOpts('psaConnectionFailure', 'psa connection fails status is {status}'),
  savingChanges: {
    message: genOpts('savingChanges.message', 'Saving Changes'),
    description: genOpts(
      'savingChanges.description',
      'Your changes are being applied. It may take a minute for your changes to appear in the PSA Billing Discrepancy report.'
    )
  },
  integrateSuccess: {
    message: genOpts('integrateSuccess.message', 'Email alerts'),
    description: genOpts(
      'integrateSuccess.description',
      'Automatic email alerts for Billing Discrepancies is switched on. Visit <em>Admin > Profile</em> to change your settings'
    )
  }
};
