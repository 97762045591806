// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  symbol: string,
  decimal: string,
  separator: string,
  precision: number
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  symbol: '$',
  decimal: '.',
  separator: ',',
  precision: 2
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/currency');
const GET_CURRENCY = wrapWithNamespace('GET_CURRENCY');
const GET_CURRENCY_SUCCESS = wrapWithNamespace('GET_CURRENCY_SUCCESS');

export const getCurrency = createAction(GET_CURRENCY);
export const getCurrencySuccess = createAction(GET_CURRENCY_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_CURRENCY_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};
