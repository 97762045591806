// @flow

import { takeLatest, put, all, fork } from 'redux-saga/effects';
import { jwtDecoder } from '@accordo-feed/micro-frontends-utils';

import * as actions from './mspData.duck';
import { SHELL_NAME } from 'src/constants';

function* getMspDataSaga(): any {
  const data = jwtDecoder.decodeToken({ shellId: SHELL_NAME });
  yield put(actions.getMspDataSuccess(data));
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getMspData, getMspDataSaga)]);
}
