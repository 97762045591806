// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  userId: string,
  expiryUnix: string,
  roles: [],
  authorization: Object,
  organizations: [],
  userMetadata: Object
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  userId: '',
  expiryUnix: '',
  roles: [],
  authorization: {},
  organizations: [],
  userMetadata: {}
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/mspData');
const GET_MSP_DATA = wrapWithNamespace('GET_MSP_DATA');
const GET_MSP_DATA_SUCCESS = wrapWithNamespace('GET_MSP_DATA_SUCCESS');

export const getMspData = createAction(GET_MSP_DATA);
export const getMspDataSuccess = createAction(GET_MSP_DATA_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_MSP_DATA_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};
