import * as R from 'ramda';

import { SAVE_STATUS } from 'src/constants';

export const genDataSource = (mapping, clients, psaClients) =>
  clients.map(({ id, name, office365Status }) => {
    const savedSelected = R.path(['psa', 0, 'id'], R.find(R.propEq('id', id))(clients));
    const mappedPsaId = R.path([id, 'id'])(mapping);
    const selected = mappedPsaId || savedSelected;
    const mappedPsaIds = [
      ...R.values(mapping).map(item => item.id),
      ...clients
        .filter(client => client.id !== id)
        .map(R.path(['psa', 0, 'id']))
        .filter(item => item)
    ];

    const status =
      selected && selected === savedSelected
        ? SAVE_STATUS.SAVED
        : R.pathOr(SAVE_STATUS.UNSAVED, [id, 'status'])(mapping);

    return {
      acoCustomer: name,
      office365Status,
      psaCustomer: {
        selected,
        status,
        isRemoving: R.pathOr(false, [id, 'isRemoving'], mapping),
        clientId: id,
        items: psaClients.filter(({ id }) => !mappedPsaIds.includes(id) || id === selected)
      }
    };
  });

/**
 * Check customer is mapped or not
 * @param {array} customers - Array of aco customer map objects
 * @return {boolean}
 */
export const isAnyCustomerMapped = customers => {
  for (let i = 0, l = customers.length; i < l; i++) {
    const customer = customers[i];
    if (R.path(['psa', 0, 'id'], customer)) return true;
  }
  return false;
};

export const getMappedNumber = clients => R.filter(R.path(['psa', 0, 'psa']))(clients).length;
