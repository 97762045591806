import * as R from 'ramda';

export const formatAndShortenNumberWithSymbol = (numberToFormat, decimalPrecision = 0) => {
  const symbols = ['K', 'M', 'B', 'T'];
  for (let i = symbols.length - 1; i >= 0; i--) {
    const decimal = Math.pow(1000, i + 1);
    if (numberToFormat <= -decimal || numberToFormat >= decimal) {
      return +(numberToFormat / decimal).toFixed(decimalPrecision) + symbols[i];
    }
  }

  return numberToFormat;
};

export const roundPercentage = num => (R.is(Number, num) && Math.round(num)) || 0;

export const camelCase = str => str.replace(/-([a-z])/g, g => g[1].toUpperCase());

export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex?.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : `0, 0, 0`;
};

export const numberFormatter = number => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};
