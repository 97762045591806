// @flow

import FileSaver from 'file-saver';
import { core } from '@accordo-feed/micro-frontends-utils';

import { API, getApiAddress, requestHandler, createDateFileName } from 'src/utils';

/***************
 *   HELPERS   *
 ***************/

const psaApi = getApiAddress(API.PSA);
const psaProductsApi = getApiAddress(API.PSA_PRODUCTS);
const psaProductMapApi = getApiAddress(API.PSA_PRODUCT_MAP);
const psaClientsApi = getApiAddress(API.PSA_CLIENTS);
const clientsApi = getApiAddress(API.CLIENTS);
const clientPsaApi = getApiAddress(API.CLIENT_PSA);
const downloadReportApi = getApiAddress(API.DOWNLOAD_REPORT);

/*****************
 *   API CALLS   *
 *****************/

export const fetchPsaConnections = (orgId: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(psaApi, { orgId })
  });

export const createPsaConnections = ({ orgId, data }: { orgId: string, data: Object }): Promise<any> =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(psaApi, { orgId }),
    data
  });

export const fetchPsaProduct = (orgId: string, psa: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(psaProductsApi, { orgId, psa })
  });

export const fetchPsaProductMap = (orgId: string, psa: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(psaProductMapApi, { orgId, psa })
  });

export const createPsaProductMap = ({ orgId, psa, data }: { orgId: string, psa: string, data: Object }): Promise<any> =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(psaProductMapApi, { orgId, psa }),
    data
  });

export const fetchPsaClients = (orgId: string, psa: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(psaClientsApi, { orgId, psa })
  });

export const fetchClients = (orgId: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(clientsApi, { orgId })
  });

export const updateClientPsa = ({ clientId, data }: { clientId: string, data: Object[] }): Promise<any> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(clientPsaApi, { clientId }),
    data
  });

export const downloadReport = ({ orgId, type }: { orgId: string, type: string }): Promise<any> =>
  requestHandler({
    url: core.replaceAll(downloadReportApi, { orgId }),
    data: {
      type
    },
    method: 'POST',
    handleBody: ({ data }) => FileSaver.saveAs(new Blob([data]), createDateFileName(type, 'csv')),
    responseType: 'blob'
  });
