// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { SAVE_STATUS } from 'src/constants';
import { wrapWithModule } from 'src/redux/utils';

/*************
 *   TYPES   *
 *************/

type ApiType = {
  data: Object[],
  isLoaded: boolean,
  isLoading: boolean
};

type StateType = {
  mapping: Object,
  clients: ApiType,
  psaClients: ApiType
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  mapping: {},
  clients: redux.genApiSchema({
    data: []
  }),
  psaClients: redux.genApiSchema({
    data: []
  })
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/psa');

const FETCH_CLIENTS = wrapWithNamespace('FETCH_CLIENTS');
const FETCH_CLIENTS_SUCCESS = wrapWithNamespace('FETCH_CLIENTS_SUCCESS');
const SET_CLIENTS_LOADING_STATE = wrapWithNamespace('SET_CLIENTS_LOADING_STATE');
const FETCH_PSA_CLIENTS = wrapWithNamespace('FETCH_PSA_CLIENTS');
const FETCH_PSA_CLIENTS_SUCCESS = wrapWithNamespace('FETCH_PSA_CLIENTS_SUCCESS');
const SET_PSA_CLIENTS_LOADING_STATE = wrapWithNamespace('SET_PSA_CLIENTS_LOADING_STATE');
const UPDATE_CLIENT_PSA = wrapWithNamespace('UPDATE_CLIENT_PSA');
const UPDATE_CLIENT_PSA_SUCCESS = wrapWithNamespace('UPDATE_CLIENT_PSA_SUCCESS');
const UPDATE_CLIENT_PSA_FAILED = wrapWithNamespace('UPDATE_CLIENT_PSA_FAILED');
const MAP_CUSTOMER = wrapWithNamespace('MAP_CUATOMER');
const REMOVE_CUSTOMER_MAPPING = wrapWithNamespace('REMOVE_CUSTOMER_MAPPING');
const REMOVE_CUSTOMER_MAPPING_SUCCESS = wrapWithNamespace('REMOVE_CUSTOMER_MAPPING_SUCCESS');
const REMOVE_CUSTOMER_MAPPING_FAILED = wrapWithNamespace('REMOVE_CUSTOMER_MAPPING_FAILED');

export const fetchPsaClients = createAction(FETCH_PSA_CLIENTS);
export const fetchPsaClientsSuccess = createAction(FETCH_PSA_CLIENTS_SUCCESS);
export const setPsaClientsLoadingState = createAction(SET_PSA_CLIENTS_LOADING_STATE);
export const fetchClients = createAction(FETCH_CLIENTS);
export const fetchClientsSuccess = createAction(FETCH_CLIENTS_SUCCESS);
export const setClientsLoadingState = createAction(SET_CLIENTS_LOADING_STATE);
export const updateClientPsa = createAction(UPDATE_CLIENT_PSA);
export const updateClientPsaSuccess = createAction(UPDATE_CLIENT_PSA_SUCCESS);
export const updateClientPsaFailed = createAction(UPDATE_CLIENT_PSA_FAILED);
export const mapCustomer = createAction(MAP_CUSTOMER);
export const removeCustomerMapping = createAction(REMOVE_CUSTOMER_MAPPING);
export const removeCustomerMappingSuccess = createAction(REMOVE_CUSTOMER_MAPPING_SUCCESS);
export const removeCustomerMappingFailed = createAction(REMOVE_CUSTOMER_MAPPING_FAILED);

/***************
 *   REDUCER   *
 ***************/

const mapCustomerReducer = (state, payload) => {
  const { mapping } = state;
  const { clientId, psaClientId } = payload;
  const mappingItem = R.propOr({}, clientId)(mapping);

  let status = SAVE_STATUS.UNSAVED;
  if (mappingItem.id && mappingItem.id === psaClientId) {
    status = mappingItem.status;
  }

  return {
    ...state,
    mapping: {
      ...mapping,
      [clientId]: {
        status,
        id: psaClientId
      }
    }
  };
};

const saveStatusReducer = status => (state, { clientId, psa }) => {
  const newState = R.mergeDeepRight(state, {
    mapping: {
      [clientId]: {
        status
      }
    }
  });

  if (status === SAVE_STATUS.SAVED) {
    const { mapping, clients, psaClients } = newState;
    const mappedPsaClientId = mapping[clientId].id;
    const mappedPsaClient = R.find(R.propEq('id', mappedPsaClientId))(psaClients.data);

    newState.clients.data = clients.data.map(item =>
      item.id === clientId
        ? {
            ...item,
            psa: [
              {
                psa,
                // $FlowIgnore
                ...R.pick(['id', 'displayName'], mappedPsaClient)
              }
            ]
          }
        : item
    );
  }

  return newState;
};

const removeStatusReducer = isRemoving => (state, { clientId }) => {
  return R.mergeDeepRight(state, {
    mapping: {
      [clientId]: {
        isRemoving
      }
    }
  });
};

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [FETCH_CLIENTS_SUCCESS]: redux.apiSuccessState('clients'),
    [SET_CLIENTS_LOADING_STATE]: redux.apiLoadingState('clients'),
    [FETCH_PSA_CLIENTS_SUCCESS]: redux.apiSuccessState('psaClients'),
    [SET_PSA_CLIENTS_LOADING_STATE]: redux.apiLoadingState('psaClients'),
    [MAP_CUSTOMER]: mapCustomerReducer,
    [UPDATE_CLIENT_PSA]: saveStatusReducer(SAVE_STATUS.SAVING),
    [UPDATE_CLIENT_PSA_SUCCESS]: saveStatusReducer(SAVE_STATUS.SAVED),
    [UPDATE_CLIENT_PSA_FAILED]: saveStatusReducer(SAVE_STATUS.UNSAVED),
    [REMOVE_CUSTOMER_MAPPING]: removeStatusReducer(true),
    [REMOVE_CUSTOMER_MAPPING_SUCCESS]: (state, clientId) => {
      const { mapping, clients } = state;

      return {
        ...state,

        clients: {
          ...clients,
          data: clients.data.map(client => {
            return client.id === clientId ? R.dissoc('psa', client) : client;
          })
        },

        mapping: R.dissoc(clientId, mapping)
      };
    },
    [REMOVE_CUSTOMER_MAPPING_FAILED]: removeStatusReducer(false)
  }[type];

  return reducer ? reducer(state, payload) : state;
};
