import * as R from 'ramda';
import { createSelector } from 'reselect';

import { CONNECTION_STATUS } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector(['psa', 'connections']);

export const isLoadingSelector = createSelector(
  moduleSelector,
  R.prop('isLoading')
);

export const isLoadedSelector = createSelector(
  moduleSelector,
  R.prop('isLoaded')
);

export const showModalSelector = createSelector(
  moduleSelector,
  R.prop('showModal')
);

export const firstDataSelector = createSelector(
  moduleSelector,
  R.path(['data', 0])
);

export const statusSelector = createSelector(
  firstDataSelector,
  R.propOr(CONNECTION_STATUS.AVAILABLE, 'status')
);

export const isConnectedSelector = createSelector(
  statusSelector,
  R.equals(CONNECTION_STATUS.ACTIVE)
);

export const psaSelector = createSelector(
  firstDataSelector,
  R.prop('psa')
);
