// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type ApiType = {
  data: Object[],
  isLoaded: boolean,
  isLoading: boolean
};

type StateType = {
  mapping: Object,
  psaProducts: ApiType,
  acoProductMap: ApiType,
  isSubmitting: boolean,
  isUpdated: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  mapping: {},
  psaProducts: {
    data: [],
    isLoaded: false,
    isLoading: false
  },
  acoProductMap: {
    data: [],
    isLoaded: false,
    isLoading: false
  },
  isSubmitting: false,
  isUpdated: false,
  isDownloading: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/psa');

const FETCH_PSA_PRODUCTS = wrapWithNamespace('FETCH_PSA_PRODUCTS');
const FETCH_PSA_PRODUCTS_SUCCESS = wrapWithNamespace('FETCH_PSA_PRODUCTS_SUCCESS');
const FETCH_PSA_PRODUCT_MAP = wrapWithNamespace('FETCH_PSA_PRODUCT_MAP');
const FETCH_PSA_PRODUCT_MAP_SUCCESS = wrapWithNamespace('FETCH_PSA_PRODUCT_MAP_SUCCESS');
const CREATE_PSA_PRODUCT_MAP = wrapWithNamespace('CREATE_PSA_PRODUCT_MAP');
const CREATE_PSA_PRODUCT_MAP_SUCCESS = wrapWithNamespace('CREATE_PSA_PRODUCT_MAP_SUCCESS');
const UPDATE_PRODUCT_MAP = wrapWithNamespace('UPDATE_PRODUCT_MAP');
const SET_PSA_PRODUCTS_LOADING_STATE = wrapWithNamespace('SET_PSA_PRODUCTS_LOADING_STATE');
const SET_ACO_PRODUCTMAP_LOADING_STATE = wrapWithNamespace('SET_ACO_PRODUCTMAP_LOADING_STATE');
const SET_UPDATED_STATE = wrapWithNamespace('SET_UPDATED_STATE');
const SET_SUBMITTING_STATE = wrapWithNamespace('SET_SUBMITTING_STATE');
const DOWNLOAD_BILLING_DISCREPANCY_REPORT = wrapWithNamespace('DOWNLOAD_BILLING_DISCREPANCY_REPORT');
const SET_DOWNLOAD_BILLING_DISCREPANCY_REPORT_STATE = wrapWithNamespace(
  'SET_DOWNLOAD_BILLING_DISCREPANCY_REPORT_STATE'
);
const MAP_PRODUCT = wrapWithNamespace('MAP_PRODUCT');

export const fetchPsaProduct = createAction(FETCH_PSA_PRODUCTS);
export const fetchPsaProductSuccess = createAction(FETCH_PSA_PRODUCTS_SUCCESS);
export const fetchPsaProductMap = createAction(FETCH_PSA_PRODUCT_MAP);
export const fetchPsaProductMapSuccess = createAction(FETCH_PSA_PRODUCT_MAP_SUCCESS);
export const createPsaProductMap = createAction(CREATE_PSA_PRODUCT_MAP);
export const createPsaProductMapSuccess = createAction(CREATE_PSA_PRODUCT_MAP_SUCCESS);
export const updateProductMap = createAction(UPDATE_PRODUCT_MAP);
export const setPsaproductsLoadingState = createAction(SET_PSA_PRODUCTS_LOADING_STATE);
export const setAcoproductmapLoadingState = createAction(SET_ACO_PRODUCTMAP_LOADING_STATE);
export const setUpdatedState = createAction(SET_UPDATED_STATE);
export const setSubmittingState = createAction(SET_SUBMITTING_STATE);
export const downloadBillingDiscrepancyReport = createAction(DOWNLOAD_BILLING_DISCREPANCY_REPORT);
export const setDownloadBillingDiscrepancyReportState = createAction(SET_DOWNLOAD_BILLING_DISCREPANCY_REPORT_STATE);
export const mapProduct = createAction(MAP_PRODUCT);

/***************
 *   REDUCER   *
 ***************/

const productMapSuccessReducer = (state, data) =>
  R.mergeDeepRight(state, {
    acoProductMap: {
      data,
      isLoaded: true,
      isLoading: false
    },
    isUpdated: false
  });

const mapProductReducer = (state, payload) => {
  const { mapping } = state;
  const { acoProductId, psaProductIds } = payload;

  return {
    ...state,
    mapping: {
      ...mapping,
      [acoProductId]: psaProductIds
    }
  };
};

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [FETCH_PSA_PRODUCTS_SUCCESS]: (state, data) =>
      R.mergeDeepRight(state, {
        psaProducts: {
          data,
          isLoaded: true,
          isLoading: false
        }
      }),

    [FETCH_PSA_PRODUCT_MAP_SUCCESS]: productMapSuccessReducer,

    [CREATE_PSA_PRODUCT_MAP_SUCCESS]: productMapSuccessReducer,

    [UPDATE_PRODUCT_MAP]: (state, payload) => ({ ...state, mapping: {} }),

    [SET_PSA_PRODUCTS_LOADING_STATE]: (state, payload) => R.assocPath(['psaProducts', 'isLoading'], payload, state),

    [SET_ACO_PRODUCTMAP_LOADING_STATE]: (state, payload) => R.assocPath(['acoProductMap', 'isLoading'], payload, state),

    [SET_SUBMITTING_STATE]: setState('isSubmitting'),

    [SET_UPDATED_STATE]: setState('isUpdated'),

    [SET_DOWNLOAD_BILLING_DISCREPANCY_REPORT_STATE]: setState('isDownloading'),

    [MAP_PRODUCT]: mapProductReducer
  }[type];

  return reducer ? reducer(state, payload) : state;
};
