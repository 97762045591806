// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { VISIBLE_MODAL } from 'src/constants';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  data: Object,
  isLoaded: boolean,
  isLoading: boolean,
  showModal: string
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  data: {},
  isLoaded: false,
  isLoading: false,
  showModal: VISIBLE_MODAL.NONE
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/psa');

const FETCH_PSA_CONNECTIONS = wrapWithNamespace('FETCH_PSA_CONNECTIONS');
const FETCH_PSA_CONNECTIONS_SUCCESS = wrapWithNamespace('FETCH_PSA_CONNECTIONS_SUCCESS');
const CREATE_PSA_CONNECTIONS = wrapWithNamespace('CREATE_PSA_CONNECTIONS');
const CREATE_PSA_CONNECTIONS_SUCCESS = wrapWithNamespace('CREATE_PSA_CONNECTIONS_SUCCESS');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');
const SHOW_MODAL = wrapWithNamespace('SHOW_MODAL');
const CLOSE_MODAL = wrapWithNamespace('CLOSE_MODAL');

export const fetchPsaConnections = createAction(FETCH_PSA_CONNECTIONS);
export const fetchPsaConnectionsSuccess = createAction(FETCH_PSA_CONNECTIONS_SUCCESS);
export const createPsaConnections = createAction(CREATE_PSA_CONNECTIONS);
export const createPsaConnectionsSuccess = createAction(CREATE_PSA_CONNECTIONS_SUCCESS);
export const setLoadingState = createAction(SET_LOADING_STATE);
export const showModal = createAction(SHOW_MODAL);
export const closeModal = createAction(CLOSE_MODAL);

/***************
 *   REDUCER   *
 ***************/

const successReducer = (state, payload) => ({
  data: payload,
  isLoaded: true,
  isLoading: false
});

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [FETCH_PSA_CONNECTIONS_SUCCESS]: successReducer,

    [CREATE_PSA_CONNECTIONS_SUCCESS]: successReducer,

    [SHOW_MODAL]: (state, payload) => ({
      ...state,
      showModal: payload
    }),

    [CLOSE_MODAL]: state => ({
      ...state,
      showModal: VISIBLE_MODAL.NONE
    }),

    [SET_LOADING_STATE]: setState('isLoading')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
