import { fork, all } from 'redux-saga/effects';

import currencySaga from './modules/currency/currency.saga';
import mspDataSaga from './modules/mspData/mspData.saga';
import psaSaga from './modules/psa/psa.saga';

const effects = [fork(currencySaga), fork(mspDataSaga), fork(psaSaga)];

export function* rootSaga() {
  yield all(effects);
}

export default effects;
