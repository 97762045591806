// @flow

import * as R from 'ramda';
import { command } from '@accordo-feed/micro-frontends';
import { core } from '@accordo-feed/micro-frontends-utils';

export const DEFAULT_LANGUAGE = 'en-us';
export const DEFAULT_COUNTRY_CODE = 'us';
export const LANGUAGE_CHOICE = 'language_choice';
export const SHELL_NAME = 'cloud-optimizer';
export const SUBMODULE_NAME = 'psa';
export const REDUCER_NAMESPACES = {
  SHELL: 'shell',
  SUBMODULE: `sub_${core.snakeToCamel(SUBMODULE_NAME)}`
};

export const PSA_NAMESPACES = {
  CONNECT_WISE: 'connectwise'
};

export const VISIBLE_MODAL = {
  ...PSA_NAMESPACES,
  NONE: ''
};

export const CONNECTION_STATUS = {
  AVAILABLE: 'available',
  ACTIVE: 'active',
  ERROR: 'error',
  NOT_FOUND: 'notFound',
  UNAUTHORIZED: 'unauthorized',
  LOCKED_USER: 'lockedUser',
  MISSING_PERMISSINGS: 'missingPermissions'
};

const getOrgIdPath = path => `/${SUBMODULE_NAME}${path}`;
const getOrgIdPaths = (paths: Object): Object => R.map(getOrgIdPath, paths);

const PATH_DEFAULT = {
  CONNECTIONS: '/',
  PRODUCT_MAPPING: '/product-mapping',
  CUSTOMER_MAPPING: '/customer-mapping'
};

export const PATHS = {
  ...getOrgIdPaths(PATH_DEFAULT),

  CUSTOMERS: '/companies'
};

// NOTE: Make sure the event keys are exactly the same as they are in the shell:
// https://bitbucket.org/accordogroup/csa.frontend/src/master/src/common/constants.js
export const MF_COMMAND_EVENTS = {
  ...command.EVENTS
};

export const SAVE_STATUS = {
  UNSAVED: 'UNSAVED',
  SAVING: 'SAVING',
  SAVED: 'SAVED'
};

export const TIME_FORMATS = {
  ISO_DATE: 'YYYY-MM-DD'
};

export const DOWNLOAD_REPORT_TYPE = {
  BILLING_RECONCILE: 'billingReconcile'
};

export const INVOKE_REDUX_ACTION_TYPE = {
  GET_ORGANIZATION: 'cloud-optimizer/modules/orgnization/GET_ORGANIZATION'
};
