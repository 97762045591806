import * as R from 'ramda';

/**
 * Get remaining objects in an array by supplying removed object ids
 * @param {array} orig - The original array of objects
 * @param {array} idsToRemove - The object IDs that needs to be removed
 * @return {array} The remaining objects in array
 */
export const getRemainingObjects = R.curry((orig, idsToRemove) =>
  R.filter(({ id }) => !R.contains(id, idsToRemove), orig)
);
