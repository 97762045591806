import { currency as currencyUtils } from '@accordo-feed/micro-frontends-utils';
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './currency.duck';
import * as selectors from './currency.selector';

function* getCurrencySaga(): any {
  try {
    const countryCode = yield select(selectors.shellCountryCodeSelector);
    const currency = currencyUtils.getCurrencyOptions(countryCode);
    yield put(actions.getCurrencySuccess(currency));
  } catch (err) {}
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getCurrency, getCurrencySaga)]);
}
