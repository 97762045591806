// @flow

import * as R from 'ramda';
import React from 'react';
import { command } from '@accordo-feed/micro-frontends';
import { notification } from 'antd';
import { takeLatest, takeEvery, put, call, all, fork, select } from 'redux-saga/effects';

import * as Styled from './psa.styled';
import * as apiCalls from './psa.api';
import * as connectionActions from './connections.duck';
import * as connectionsSelectors from 'src/pages/connections/connections.selector';
import * as customerActions from './customerMapping.duck';
import * as customerMappingSelectors from 'src/pages/customerMapping/customerMapping.selector';
import * as mspDataSelectors from 'src/redux/modules/mspData/mspData.selector';
import * as productActions from './productMapping.duck';
import lang from './psa.lang';
import { API, getApiAddress } from 'src/utils';
import {
  CONNECTION_STATUS,
  SUBMODULE_NAME,
  MF_COMMAND_EVENTS,
  DOWNLOAD_REPORT_TYPE,
  INVOKE_REDUX_ACTION_TYPE
} from 'src/constants';

const psaApi = getApiAddress(API.PSA);
const { savingChanges: savingChangesLang, integrateSuccess: integratedSuccessLang } = lang;

const showSavingChangesNoti = translate =>
  notification.success({
    message: translate(savingChangesLang.message),
    description: translate(savingChangesLang.description)
  });

const showIntegratedNoti = translate =>
  notification.info({
    duration: 10,
    message: translate(integratedSuccessLang.message),
    description: (
      <Styled.IntegrateSuccessDesc
        dangerouslySetInnerHTML={{ __html: translate(integratedSuccessLang.description) }}
      ></Styled.IntegrateSuccessDesc>
    )
  });

function* fetchPsaConnectionsSaga(): any {
  const orgId = yield select(mspDataSelectors.orgIdSelector);

  yield put(connectionActions.setLoadingState(true));

  try {
    const data = yield apiCalls.fetchPsaConnections(orgId);
    yield put(connectionActions.fetchPsaConnectionsSuccess(data));
  } catch (err) {
    yield put(connectionActions.setLoadingState(false));
  }
}

function* createPsaConnectionsSaga({ payload }): any {
  const { psa, connection, translate } = payload;
  const orgId = yield select(mspDataSelectors.orgIdSelector);

  yield put(connectionActions.setLoadingState(true));

  try {
    const result = yield apiCalls.createPsaConnections({
      orgId,
      data: [
        {
          psa,
          connection
        }
      ]
    });

    yield put(connectionActions.createPsaConnectionsSuccess(result));

    const status = yield select(connectionsSelectors.statusSelector);

    // If connection fails, it shows an error toaster
    if (status === CONNECTION_STATUS.ACTIVE) {
      showIntegratedNoti(translate);
      yield call(command.invokeReduxAction, INVOKE_REDUX_ACTION_TYPE.GET_ORGANIZATION);
    } else {
      command.dispatch(MF_COMMAND_EVENTS.API_ERROR, {
        error: {
          message: translate(lang.psaConnectionFailure, { status }),
          config: {
            url: psaApi
          }
        },
        submoduleName: SUBMODULE_NAME
      });
    }

    yield put(connectionActions.closeModal());
  } catch (e) {
    yield put(connectionActions.setLoadingState(false));
  }
}

function* fetchPsaProductSaga(): any {
  const orgId = yield select(mspDataSelectors.orgIdSelector);
  const psa = yield select(connectionsSelectors.psaSelector);

  yield put(productActions.setPsaproductsLoadingState(true));

  try {
    const data = yield apiCalls.fetchPsaProduct(orgId, psa);
    yield put(productActions.fetchPsaProductSuccess(data));
  } catch (err) {
    yield put(productActions.setPsaproductsLoadingState(false));
  }
}

function* fetchPsaProductMapSaga(): any {
  const orgId = yield select(mspDataSelectors.orgIdSelector);
  const psa = yield select(connectionsSelectors.psaSelector);

  yield put(productActions.setAcoproductmapLoadingState(true));

  try {
    const data = yield apiCalls.fetchPsaProductMap(orgId, psa);
    yield put(productActions.fetchPsaProductMapSuccess(data));
    yield put(productActions.setAcoproductmapLoadingState(false));
  } catch (err) {
    yield put(productActions.setAcoproductmapLoadingState(false));
  }
}

function* createPsaProductMapSaga({ payload: { data, translate } }): any {
  const psa = yield select(connectionsSelectors.psaSelector);
  const orgId = yield select(mspDataSelectors.orgIdSelector);

  yield put(productActions.setAcoproductmapLoadingState(true));
  yield put(productActions.setSubmittingState(true));

  try {
    const result = yield apiCalls.createPsaProductMap({
      orgId,
      psa,
      data
    });
    yield put(productActions.createPsaProductMapSuccess(result));
    yield put(productActions.setSubmittingState(false));
    showSavingChangesNoti(translate);
  } catch (e) {
    yield put(productActions.setAcoproductmapLoadingState(false));
    yield put(productActions.setSubmittingState(false));
  }
}

function* downloadBillingDiscrepancyReportSaga({ payload }): any {
  yield put(productActions.setDownloadBillingDiscrepancyReportState(true));
  const orgId = yield select(mspDataSelectors.orgIdSelector);

  try {
    yield apiCalls.downloadReport({ orgId, type: DOWNLOAD_REPORT_TYPE.BILLING_RECONCILE });
    yield put(productActions.setDownloadBillingDiscrepancyReportState(false));
  } catch (err) {
    yield put(productActions.setDownloadBillingDiscrepancyReportState(false));
  }
}

function* fetchPsaClientsSaga(): any {
  const orgId = yield select(mspDataSelectors.orgIdSelector);
  const psa = yield select(connectionsSelectors.psaSelector);

  yield put(customerActions.setPsaClientsLoadingState(true));

  try {
    const data = yield apiCalls.fetchPsaClients(orgId, psa);
    yield put(customerActions.fetchPsaClientsSuccess(data));
  } catch (err) {
    yield put(customerActions.setPsaClientsLoadingState(false));
  }
}

function* fetchClientsSaga(): any {
  const orgId = yield select(mspDataSelectors.orgIdSelector);

  yield put(customerActions.setClientsLoadingState(true));

  try {
    const data = yield apiCalls.fetchClients(orgId);
    yield put(customerActions.fetchClientsSuccess(data));
  } catch (err) {
    yield put(customerActions.setClientsLoadingState(false));
  }
}

function* updateClientPsaSaga({ payload: { clientId, translate } }): any {
  const psa = yield select(connectionsSelectors.psaSelector);
  const mapping = yield select(customerMappingSelectors.mappingSelector);
  const psaClientsData = yield select(customerMappingSelectors.psaClientsDataSelector);

  const psaClientId = mapping[clientId].id;
  const psaClient = R.find(R.propEq('id', psaClientId))(psaClientsData);

  try {
    yield apiCalls.updateClientPsa({
      clientId,
      data: [
        {
          psa,
          ...R.pick(['id', 'displayName'], psaClient)
        }
      ]
    });

    yield put(
      customerActions.updateClientPsaSuccess({
        psa,
        clientId
      })
    );

    showSavingChangesNoti(translate);
  } catch (e) {
    yield put(customerActions.updateClientPsaFailed({ clientId }));
  }
}

function* removeCustomerMappingSaga({ payload: { clientId, translate } }): any {
  try {
    yield apiCalls.updateClientPsa({
      clientId,
      data: []
    });
    yield put(customerActions.removeCustomerMappingSuccess(clientId));
    showSavingChangesNoti(translate);
  } catch (e) {
    yield put(customerActions.removeCustomerMappingFailed(clientId));
  }
}

export default function*(): any {
  yield all([
    fork(takeLatest, connectionActions.fetchPsaConnections, fetchPsaConnectionsSaga),
    fork(takeLatest, connectionActions.createPsaConnections, createPsaConnectionsSaga),
    fork(takeLatest, productActions.fetchPsaProduct, fetchPsaProductSaga),
    fork(takeLatest, productActions.fetchPsaProductMap, fetchPsaProductMapSaga),
    fork(takeLatest, productActions.createPsaProductMap, createPsaProductMapSaga),
    fork(takeLatest, productActions.downloadBillingDiscrepancyReport, downloadBillingDiscrepancyReportSaga),
    fork(takeLatest, customerActions.fetchPsaClients, fetchPsaClientsSaga),
    fork(takeLatest, customerActions.fetchClients, fetchClientsSaga),
    fork(takeEvery, customerActions.updateClientPsa, updateClientPsaSaga),
    fork(takeEvery, customerActions.removeCustomerMapping, removeCustomerMappingSaga)
  ]);
}
