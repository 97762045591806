// @flow

import { apiCommon } from '@accordo-feed/micro-frontends-utils';

import { SUBMODULE_NAME } from 'src/constants';

/*************
 *   TYPES   *
 *************/

declare var __DEPLOYMENT_ENV__: string;

/***************
 *   HELPERS   *
 ***************/

const getSearchProxyPath = indexName => `/search-service/index/${indexName}/organizations/{{ orgId }}`;

/*******************
 *   API MAPPING   *
 *******************/

export const API = {
  SEARCH_BATCH_INDEXES: 'SEARCH_BATCH_INDEXES',
  PSA: 'PSA',
  PSA_PRODUCTS: 'PSA_PRODUCTS',
  PSA_PRODUCT_MAP: 'PSA_PRODUCT_MAP',
  PSA_CLIENTS: 'PSA_CLIENTS',
  CLIENTS: 'CLIENTS',
  CLIENT_PSA: 'CLIENT_PSA',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT'
};

export const API_PATHS = {
  // Integrations-service
  // https://bitbucket.org/accordogroup/integrations-service/src/master/docs/swagger.yml?at=master&fileviewer=file-view-default
  PSA: '/integrations/organizations/{{ orgId }}/psa',
  PSA_PRODUCTS: '/integrations/organizations/{{ orgId }}/psa/{{ psa }}/products',
  PSA_PRODUCT_MAP: '/integrations/organizations/{{ orgId }}/psa/{{ psa }}/productMap',
  PSA_CLIENTS: '/integrations/organizations/{{ orgId }}/psa/{{ psa }}/clients',

  // Search Service API
  // https://bitbucket.org/accordogroup/search-service/src/master/docs/swagger.yml
  CLIENTS: '/search-service/organizations/{{ orgId }}/clients',

  // Organization API
  // https://bitbucket.org/accordogroup/organization-api/src/master/docs/swagger.yaml
  CLIENT_PSA: '/organizations/aco/client/{{ clientId }}/psa',

  // Reporting Service API
  // https://bitbucket.org/accordogroup/reporting-services/src/master/docs/swagger.yaml
  DOWNLOAD_REPORT: '/reporting-services/msp/{{ orgId }}/download-report'
};

const apiMapping = {
  // IndexProxy:
  // https://bitbucket.org/accordogroup/search-service/src/master/docs/swagger.yml
  [API.SEARCH_BATCH_INDEXES]: `${getSearchProxyPath('batch')}`,
  [API.PSA]: API_PATHS.PSA,
  [API.PSA_PRODUCTS]: API_PATHS.PSA_PRODUCTS,
  [API.PSA_PRODUCT_MAP]: API_PATHS.PSA_PRODUCT_MAP,
  [API.PSA_CLIENTS]: API_PATHS.PSA_CLIENTS,
  [API.CLIENTS]: API_PATHS.CLIENTS,
  [API.CLIENT_PSA]: API_PATHS.CLIENT_PSA,
  [API.DOWNLOAD_REPORT]: API_PATHS.DOWNLOAD_REPORT
};

const apiConfig = new apiCommon.ApiConfig({
  apiMapping,
  env: __DEPLOYMENT_ENV__,
  storageName: SUBMODULE_NAME
});

export const getApiAddress = (key: string): string => apiConfig.get(key);

export const requestHandler = apiCommon.requestHandler(SUBMODULE_NAME);
