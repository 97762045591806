import * as R from 'ramda';
import { createSelector } from 'reselect';
import { core } from '@accordo-feed/micro-frontends-utils';

import { DEFAULT_COUNTRY_CODE, REDUCER_NAMESPACES } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';

const shellOrgizationSelector = getModuleSelector('organization', REDUCER_NAMESPACES.SHELL);

const shellOrganizationDataSelector = createSelector(
  shellOrgizationSelector,
  R.prop('data')
);

// Try to get the country code from shell (msp account), if null fall back to 'us'
const pipeProcessCountryCode = R.pipeWith((f, res) => (core.isEmptyOrNil(res) ? DEFAULT_COUNTRY_CODE : f(res)));

export const shellCountryCodeSelector = createSelector(
  shellOrganizationDataSelector,
  pipeProcessCountryCode([R.prop('countryCode'), R.toLower()])
);

export const currencySelector = getModuleSelector('currency');
